import './index.css'
import CutoutsTexture from '../cutouts-texture/'
import Menu from '../menu';

const About = props => {
    return (
        <>
            <div className="about outskirt" style={{ color: 'white' }}>
                <CutoutsTexture
                    foreground="#4848CC"
                    background="black"
                    style={{ width: '100vw', height: '100vh', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1, overflow: 'hidden' }} />
                <div className='about-overlaid'>
                    <Menu color="white" />
                    <div className="about-content">
                        <h2>I am a designer based in <span className="highlight">Toronto</span>, Canada, currently pursuing a Master of Architecture degree at the University of California, <span className="highlight">Berkeley</span>.
                            I blend architectural vision with engineering precision, and craft spaces where innovation meets structural integrity.
                        </h2>
                    </div>
                </div>
            </div>
        </>

    )
}

export default About